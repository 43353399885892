import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.storeStatistics,
    component: () => import('../../pages/sellerSide/storeStatistics'),
    meta: {
      title: '数据统计'
    }
  }
] as TRoutes[]
