import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.commodityManage,
    component: () => import('../../pages/commodityManagement/commodityManage'),
    meta: {
      title: '商品列表'
    }
  },
  {
    path: routeNames.addCommodity,
    component: () => import('../../pages/commodityManagement/commodityManage/addCommodity'),
    meta: {
      title: '添加商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.freightTemplate,
    component: () => import('../../pages/commodityManagement/freightTemplate'),
    meta: {
       title: '运费模板'
    }
  },
  {
    path: routeNames.addFreight,
    component: () => import('../../pages/commodityManagement/freightTemplate/addFreight'),
    meta: {
      title: '运费模板',
      hideInMenu: true
    }
  }  

] as TRoutes[]
