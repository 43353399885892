import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.storeOrder,
    component: () => import('../../pages/sellerSide/storeOrder'),
    meta: {
      title: '订单管理'
    }
  },
  {
    path: routeNames.storeOrderDetail,
    component: () => import('../../pages/sellerSide/storeOrder/detail'),
    meta: {
      title: '订单详情',
      hideInMenu: true
    }
  },
 
  {
    path: routeNames.afterSalesManagement,
    component: () => import('../../pages/sellerSide/afterSalesManagement'),
    meta: {
      title: '售后管理'
    }
  }
 

] as TRoutes[]
