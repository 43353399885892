import { IRequestConfig } from '~/modules/@wmeimob/request/src/types/fetch-type'
import {
  AdminApiExpresstemplateareaQueryGetParams,
  AdminApiExpresstemplatedetailsQueryGetParams,
  AdminApiExpresstemplateQueryAllExpressTemplateListGetParams,
  AdminApiExpresstemplateQueryExpressTemplateDetailGetParams,
  AdminApiExpresstemplateQueryExpressTemplateListGetParams,
  AdminApiGoodsQueryGoodsDetailGetParams,
  AdminApiGoodsQueryGoodsPageGetParams,
  ExpressTemplate,
  ExpressTemplateArea,
  ExpressTemplateDetails,
  ExpressTemplateDto,
  GoodsDto,
  JsonResult,
  JsonResultExpressTemplate,
  JsonResultExpressTemplateArea,
  JsonResultExpressTemplateDetails,
  JsonResultExpressTemplateVo,
  JsonResultGoodsDetailVo,
  JsonResultListExpressTemplate,
  JsonResultObject,
  JsonResultPageInfoExpressTemplate,
  JsonResultPageInfoGoods,
  JsonResultString,
  JsonResultVoid,
  LoginDto
} from './data-contracts'
import requestInstance from './instance'

type RequestConfig = Omit<IRequestConfig, 'url' | 'method'>

export const API = {
  //商家端
  '/admin/merchant/login_POST': (loginDto: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/auth/login`,
      method: 'POST',
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //获取自营认证详情
  '/merchant/api/authentication_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/authentication`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //提交自营认证信息
  '/merchant/api/authentication_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/authentication`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //获取店铺详情
  '/merchant/api/store_GET': (query, options = {}) =>
    requestInstance({
      // url: `/merchant/api/store`,
      url: `/merchant/api/store`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //修改店铺信息
  '/merchant/api/store_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //获取店铺账号信息
  '/merchant/api/account/authentication_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/account/authentication`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
      
    //获取账号信息
  '/merchant/auth/user/info_GET': (query, options = {}) =>
  requestInstance({
    url: `/merchant/auth/user/info`,
    method: 'GET',
    params: query,
    ...options
  }) as unknown as Promise<any>,
  //修改店铺账号信息
  '/merchant/api/account/authentication_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/account/authentication`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  /**
   * No description
   * @name POST /merchant/api/expresstemplate
   * @summary 新增
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate_POST': (t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplate
   * @summary 修改
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate_PUT': (t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplate/addTemplate
   * @summary 新增模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/addTemplate_POST': (entity: ExpressTemplateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/addTemplate`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplate/deleteTemplate/{id}
   * @summary 删除模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplate/deleteTemplate/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/deleteTemplate/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryAllExpressTemplateList
   * @summary 查询所有运费模板
   * @tags 运费模板表
   * @response `200` `JsonResultListExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryAllExpressTemplateList_GET': (
    query: AdminApiExpresstemplateQueryAllExpressTemplateListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryAllExpressTemplateList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListExpressTemplate>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryExpressTemplateDetail
   * @summary 模板详情
   * @tags 运费模板表
   * @response `200` `JsonResultExpressTemplateVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryExpressTemplateDetail_GET': (
    query: AdminApiExpresstemplateQueryExpressTemplateDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryExpressTemplateDetail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateVo>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryExpressTemplateList
   * @summary 运费模板表分页查询
   * @tags 运费模板表
   * @response `200` `JsonResultPageInfoExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryExpressTemplateList_GET': (
    query: AdminApiExpresstemplateQueryExpressTemplateListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryExpressTemplateList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoExpressTemplate>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplate/updateTemplate
   * @summary 编辑模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/updateTemplate_PUT': (entity: ExpressTemplateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/updateTemplate`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/{id}
   * @summary 详情
   * @tags 运费模板表
   * @response `200` `JsonResultExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplate>,
  /**
   * No description
   * @name PUT /admin/api/expresstemplate/{id}
   * @summary 修改
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/{id}_PUT': (id: number, t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplate/{id}
   * @summary 删除
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplate/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplatearea
   * @summary 新增
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea_POST': (t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatearea
   * @summary 修改
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea_PUT': (t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatearea/query
   * @summary 运费模版地址分页查询
   * @tags 运费模版地址
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/query_GET': (query: AdminApiExpresstemplateareaQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatearea/{id}
   * @summary 详情
   * @tags 运费模版地址
   * @response `200` `JsonResultExpressTemplateArea` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateArea>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatearea/{id}
   * @summary 修改
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/{id}_PUT': (id: number, t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplatearea/{id}
   * @summary 删除
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplatearea/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplatedetails
   * @summary 新增
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails_POST': (t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/expresstemplatedetails
   * @summary 修改
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails_PUT': (t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatedetails/query
   * @summary 运费模版详情分页查询
   * @tags 运费模版详情
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/query_GET': (query: AdminApiExpresstemplatedetailsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/expresstemplatedetails/{id}
   * @summary 详情
   * @tags 运费模版详情
   * @response `200` `JsonResultExpressTemplateDetails` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateDetails>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatedetails/{id}
   * @summary 修改
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/{id}_PUT': (id: number, t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplatedetails/{id}
   * @summary 删除
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplatedetails/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  /**
   * No description
   * @name GET /merchant/api/goods/page
   * @summary 商品实体表分页查询
   * @tags 商品实体表
   * @response `200` `JsonResultPageInfoGoods` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods/page_GET': (query: AdminApiGoodsQueryGoodsPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoods>,

  /**
   * No description
   * @name PUT /merchant/api/goods/sale
   * @summary 修改商品状态
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods/sale_PUT': (entity: GoodsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods/sale`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  //获取商品分类列表(普通列表)
  '/merchant/api/goods/classify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  /**
   * No description
   * @name PUT /merchant/api/goods
   * @summary 编辑商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_PUT': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name POST /merchant/api/goods
   * @summary 新增商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_POST': (entity: GoodsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name DELETE /merchant/api/goods
   * @summary 删除商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/goods_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/goods
   * @summary 查询商品详情
   * @tags 商品实体表
   * @response `200` `JsonResultGoodsDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_GET': (query: AdminApiGoodsQueryGoodsDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultGoodsDetailVo>,

  //评价管理
  '/merchant/api/goods/evaluation/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //评价回复
  '/merchant/api/goods/evaluation/reply_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/reply`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //评价回复
  '/merchant/api/goods/evaluation/review/reply_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/review/reply`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //评价申诉
  '/merchant/api/goods/evaluation/appeal_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/appeal`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询优惠卷列表
  '/merchant/api/store/coupon/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增优惠券
  '/merchant/api/store/coupon_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新优惠卷
  '/merchant/api/store/coupon_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠券-删除
  '/merchant/api/store/coupon_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠卷上架/下架
  '/merchant/api/store/coupon/enable_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/enable`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠券-部分商品
  '/merchant/api/store/coupon/goods/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //更新优惠券-部分商品
  '/merchant/api/store/coupon/goods_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //优惠券-删除部分商品
  '/merchant/api/store/coupon/goods_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询拼团商品列表
  '/merchant/api/combination/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询拼团列表
  '/merchant/api/ms/pink/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/pink/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团列表-详情
  '/merchant/api/ms/pink/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/pink/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团商品详情
  '/merchant/api/combination_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询可选择的商品列表（用户拼团和秒杀选择商品）
  '/merchant/api/goods/select_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/select`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增拼团商品
  '/merchant/api/combination_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新拼团商品
  '/merchant/api/combination_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团商品上架/下架
  '/merchant/api/combination/sale_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination/sale`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //删除拼团商品
  '/merchant/api/combination_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询秒杀商品列表
  '/merchant/api/seckill/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //删除秒杀商品
  '/merchant/api/seckill_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增秒杀商品
  '/merchant/api/seckill_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新秒杀商品
  '/merchant/api/seckill_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //秒杀商品上架/下架
  '/merchant/api/seckill/sale_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill/sale`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //查询秒杀商品详情
  '/merchant/api/seckill_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单管理
  '/merchant/api/ms/order/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //订单详情
  '/merchant/api/ms/order/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //订单-申请配送列表
  '/merchant/api/ms/order/delivery/audit/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/audit/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //申请平台配送
  '/merchant/api/ms/order/deliver/apply_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/apply`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //批量申请平台配送
  '/merchant/api/ms/order/deliver/apply/batch_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/apply/batch`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //批量发货
  '/merchant/api/ms/order/delivery/pack/batch_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/pack/batch`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //商家配送
  '/merchant/api/ms/order/deliver/merchant_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/merchant`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //物流发货
  '/merchant/api/ms/order/express_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/express`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //自提
  '/merchant/api/ms/order/delivery/self_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/self`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //售后-列表
  '/merchant/api/ms/refundOrder/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //售后-详情
  '/merchant/api/ms/refundOrder/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //审核通过
  '/merchant/api/ms/refundOrder/audit/pass_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/audit/pass`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //审核不通过
  '/merchant/api/ms/refundOrder/audit/refuse_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/audit/refuse`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-账单列表
  '/merchant/api/ms/settlement/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 确认结算
  '/merchant/api/ms/settlement/confirm_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/confirm`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //财务管理-账单列表-申诉
  '/merchant/api/ms/appeal_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/appeal`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-商城优惠券结算列表
  '/merchant/api/ms/settlement/coupon/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/coupon/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-商城优惠券结算列表-确认
  '/merchant/api/ms/settlement/coupon_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/coupon`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //优惠券使用列表
  '/merchant/api/store/coupon/statistics/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/statistics/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //优惠券使用列表-统计
  '/merchant/api/store/coupon/statistics_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/statistics`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //今日销售总额/订单量
  '/merchant/api/store/statistics/today_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/today`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //商品销售排行榜（前十）
  '/merchant/api/store/statistics/goods/rank_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/goods/rank`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //商品统计
  '/merchant/api/store/statistics/goods_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/goods`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //订单情况
  '/merchant/api/store/statistics/order/state_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/order/state`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单统计
  '/merchant/api/store/statistics/order_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/order`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //销售统计
  '/merchant/api/store/statistics/sale_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/statistics/sale`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询优惠卷领取列表
  '/merchant/api/store/coupon/member/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/member/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //自提（发货）
  '/merchant/api/ms/order/delivery/pack_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/pack`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //商品导入
  '/merchant/api/goods/import_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods/import`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,

  //确认退款
  '/merchant/api/ms/refundOrder/doRefund_POST': (query: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/doRefund`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  //确认不退款
  '/merchant/api/ms/refundOrder/notRefund_POST': (query: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/notRefund`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<JsonResult>,
    '/merchant/api/ms/order/preview/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/preview/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>
}
