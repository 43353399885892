import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.groupBuying,
    component: () => import('../../pages/sellerSide/groupBuying'),
    meta: {
      title: '拼团商品'
    }
  },
  {
    path: routeNames.groupBuyingList,
    component: () => import('../../pages/sellerSide/groupBuying/list'),
    meta: {
      title: '拼团列表'
    }
  },

  {
    path: routeNames.addGroupBuying,
    component: () => import('../../pages/sellerSide/groupBuying/add'),
    meta: {
      title: '编辑拼团',
      hideInMenu: true
    }
  },
  {
    path: routeNames.activityAddCommodity,
    component: () => import('../../pages/sellerSide/activityAddCommodity'),
    meta: {
      title: '添加商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.seckill,
    component: () => import('../../pages/sellerSide/seckill'),
    meta: {
      title: '秒杀列表'
    }
  },
  {
    path: routeNames.addSeckill,
    component: () => import('../../pages/sellerSide/seckill/add'),
    meta: {
      title: '编辑秒杀',
      hideInMenu: true
    }
  }
 

] as TRoutes[]
