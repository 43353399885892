import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.billList,
    component: () => import('../../pages/sellerSide/storeFinanceManagement'),
    meta: {
      title: '账单列表'
    }
  },

 
  {
    path: routeNames.settlementList,
    component: () => import('../../pages/sellerSide/storeFinanceManagement/coupon'),
    meta: {
      title: '优惠券结算列表'
    }
  }
 

] as TRoutes[]
