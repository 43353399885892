import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.storeInfo,
    component: () => import('../../pages/sellerSide/StoreInformation/info'),
    meta: {
      title: '店铺信息'
    }
  },

  {
    path: routeNames.selfOperatedCertification,
    component: () => import('../../pages/sellerSide/StoreInformation'),
    meta: {
      title: '自营认证'
    }
  },
  {
    path: routeNames.accountSettings,
    component: () => import('../../pages/sellerSide/StoreInformation/account'),
    meta: {
      title: '更改账号'
    }
  }

] as TRoutes[]
