import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.storeEvaluateManagement,
    component: () => import('../../pages/sellerSide/storeEvaluateManagement'),
    meta: {
      title: '评价管理'
    }
  }


] as TRoutes[]
