import { createBrowserHistory, createHashHistory } from 'history'
import iconFile from './images/icon_file.jpg';
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNoAuth from '~/pages/403'


//商家端
import storeInformation from './modules/storeInformation'
import storeGoodsManagement from './modules/storeGoodsManagement'
import storeEvaluateManagement from './modules/storeEvaluateManagement'
import storeCouponManagement from './modules/storeCouponManagement'
import marketingActivities from './modules/marketingActivities'
import storeOrderManagement from './modules/storeOrderManagement'
import storeFinanceManagement from './modules/storeFinanceManagement'
import storeStatistics from './modules/storeStatistics'
import businessLogin from '../pages/businessLogin'
import { AppstoreOutlined, BgColorsOutlined, ClusterOutlined, FundOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons'
import { routeType } from '~/config';

export const history = routeType === 'hash' ? createHashHistory() : createBrowserHistory()

export const routers: IRoute[] = [
  { path: routeNames.login, component: businessLogin, meta: { title: '商家端登录', sync: true, noLogin: true } },
  {
    path: '/',
    layout: BasicLayout,
    children: [
        // 以下是商家端
      { name: '店铺信息', path: '/storeInformation', icon: FundOutlined, children: storeInformation },
      { name: '商品管理', path: '/storeGoodsManagement', icon: FundOutlined, children: storeGoodsManagement },
      { name: '营销管理', path: '/marketingActivities', icon: FundOutlined, children: marketingActivities },
      { name: '优惠券管理', path: '/storeCouponManagement', icon: FundOutlined, children: storeCouponManagement },
      { name: '评价管理', path: '/storeEvaluateManagement', icon: FundOutlined, children: storeEvaluateManagement },
      { name: '订单管理', path: '/storeOrderManagement', icon: FundOutlined, children: storeOrderManagement },
      { name: '财务管理', path: '/storeFinanceManagement', icon: FundOutlined, children: storeFinanceManagement },
      { name: '数据统计', path: '/storeStatistics', icon: FundOutlined, children: storeStatistics },
      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
    ]
  }
]


const { routes } = generateRouteAndMenu(routers)
export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }
