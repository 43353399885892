import { LogoutOutlined, KeyOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Space, Input, Modal, Form, Button, message } from 'antd'
import { useContext, FC, memo, useCallback, useState, useEffect } from 'react'
import type { MenuInfo } from 'rc-menu/lib/interface'
import globalContext from '~/contexts/global.context'
import { api } from '~/request'
import { useHistory } from 'react-router-dom'
import { routeNames } from '~/routes/const'
import { IUserInfoProps } from './const'
import avatar from '~/assets/images/default_avatar.png'
const Component: FC<IUserInfoProps> = (props) => {
  // const { name, dispatch } = useContext(globalContext)
  // const name = window.localStorage.getItem('stroeName')
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState('')
  const { confirm } = Modal
  useEffect(() => {
    api['/merchant/api/store_GET']({}).then((res) => {
      setName(res.data.name)
    })
    getCount()
  }, [])

  const getCount = () => {
    api['/merchant/api/ms/order/preview/page_GET']({}).then((res) => {
      if (res.data.length > 0) {
        Modal.destroyAll()
        confirm({
          title: '新的订单提醒',
          content: '你有新的订单，请快去处理吧',
          okText: '去处理',
          onOk() {
            Modal.destroyAll()
            history.replace(`/storeOrder`)
            setTimeout(() => {
              getCount()
            }, 10000)
          },
          onCancel() {
            setTimeout(() => {
              getCount()
            }, 10000)
          }
        })
      }else{
        setTimeout(() => {
          getCount()
        }, 10000)
      }
      console.error()
      // setName(res.data.name)
    })
  }

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event
      if (key === 'logout') {
        window.localStorage.removeItem('Authorization')
        window.localStorage.removeItem('stroeName')
        // dispatch({ name: '' })
        history.replace(routeNames.login)
        return
      } else if (key === 'psd') {
        setVisible(true)
      }
    },
    [history]
  )

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menuHeaderDropdown}>
        <Space>
          <Avatar src={avatar} />
          <span>{name}, 您好</span>
        </Space>
      </Dropdown>
    </>
  )
}

Component.defaultProps = {}
Component.displayName = 'UserInfo'

const UserInfo = memo(Component)
export default UserInfo
