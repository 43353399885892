import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';

export default [
  {
    path: routeNames.storeCouponManageList,
    component: () => import('../../pages/sellerSide/couponManage/couponManageList'),
    meta: {
      title: '优惠券列表'
    }
  },
  {
    path: routeNames.storeAllCommodity,
    component: () => import('../../pages/sellerSide/couponManage/couponManageList/allCommodity'),
    meta: {
      title: '优惠券商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.storeParticipateCommodity,
    component: () => import('../../pages/sellerSide/couponManage/couponManageList/participateCommodity'),
    meta: {
      title: '优惠券商品',
      hideInMenu: true
    }
  },
  
  {
    path: routeNames.storeReceiveList,
    component: () => import('../../pages/sellerSide/couponManage/receiveList'),
    meta: {
      title: '优惠券领取列表'
    }
  },
  {
    path: routeNames.storeCouponUseManageList,
    component: () => import('../../pages/sellerSide/couponManage/storeCouponUseManageList'),
    meta: {
      title: '优惠券使用列表'
    }
  }

] as TRoutes[]
